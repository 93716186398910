<template>
<div id="complete">
     <!-- Page Content-->
    <div class="container pb-5 mb-sm-4">
      <div class="pt-5">
        <div class="card py-3 mt-sm-3">
          <div class="card-body text-center">
            <h2 class="h4 pb-3">Thank you for your order!</h2>
            <p class="font-size-sm mb-2">Your order has been placed and will be processed as soon as possible.</p>
            <p class="font-size-sm mb-2">Make sure you make note of your order number, which is <span class='font-weight-medium'>34VB5540K83.</span></p>
            <p class="font-size-sm">You will be receiving an email shortly with confirmation of your order.</p><router-link class="btn btn-secondary mt-3 mr-3" to="/product">Go back shopping</router-link><router-link class="btn btn-primary mt-3" to="/order-tracking"><i class="czi-location"></i>&nbsp;Track order</router-link>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>


export default {
  name: 'CheckoutComplete',
  components: {

  }

}
</script>