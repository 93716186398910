<template>
   <div>
     
     <!-- Page Title-->
    <div class="page-title-overlap bg-dark pt-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap"><a href="#">Shop</a>
              </li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Diamonds</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 text-light mb-0">Diamonds</h1>
        </div>
      </div>
    </div>

      <!-- Page Content-->
      <div class="container pb-5 mb-2 mb-md-4">
        <div class="row">

         

    <section class="col-lg-12">
          <!-- Toolbar-->
          <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
            <div class="d-flex flex-wrap">
              <div class="form-inline flex-nowrap mr-3 mr-sm-4 pb-3">
                <!-- <label class="text-light opacity-75 text-nowrap mr-2 d-none d-sm-block" for="sorting">Sort by:</label>
                <select class="form-control custom-select" id="sorting">
                  <option>Popularity</option>
                  <option>Low - Hight Price</option>
                  <option>High - Low Price</option>
                  <option>Average Rating</option>
                  <option>A - Z Order</option>
                  <option>Z - A Order</option>
                </select><span class="font-size-sm text-light opacity-75 text-nowrap ml-2 d-none d-md-block">of 287 products</span> -->
              </div>
            </div>
            <div class="d-flex pb-3">
              <!-- <a class="nav-link-style nav-link-light mr-3" href="#"><i class="czi-arrow-left"></i></a><span class="font-size-md text-light">1 / 5</span><a class="nav-link-style nav-link-light ml-3" href="#"><i class="czi-arrow-right"></i></a> -->
            </div>
           
          </div>

       

          <!-- Products grid-->
          <div class="row mx-n2" v-if="products.length > 0">
            <!-- Product-->
            <div  class="col-md-3 col-sm-6 px-2 mb-4" v-for="itemProduct in products" v-bind:key="itemProduct.id">
            <div  v-if="itemProduct.price > Number(maximum)" >
              
                <div class="card product-card" >
                  <!-- <button class="btn-wishlist btn-sm" type="button" data-toggle="tooltip" data-placement="left" title="Add to wishlist"><i class="czi-heart"></i></button> -->
                  <router-link v-bind:to="'/product/detail/'+itemProduct.id" class="card-img-top d-block overflow-hidden" style="height:200px;"><img v-bind:src="itemProduct.galleries[0].photo" alt="Product" class="img-fluid" width="100%" ></router-link>
                  <div class="card-body py-2"><a class="product-meta d-block font-size-xs pb-1" href="#">{{ itemProduct.type }}</a>
                    <h3 class="product-title font-size-sm"><router-link v-bind:to="'/product/detail/'+itemProduct.id" >{{ itemProduct.name }}</router-link></h3>
                    <div class="d-flex justify-content-between">
                      <div class="product-price"><span class="text-accent">Rp {{ itemProduct.price.toLocaleString('id-ID') }}</span></div>
                      <!-- <div class="star-rating"><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star-filled active"></i><i class="sr-star czi-star"></i></div> -->
                    </div>
                  </div>

                  <div class="card-body card-body-hidden" v-if="itemProduct.quantity > 0">
                  <a @click="saveKeranjang(itemProduct.id, itemProduct.name, itemProduct.price, itemProduct.galleries[0].photo)"  class="btn btn-primary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast"><i class="czi-cart font-size-sm mr-1"></i>Add to Cart</a>
                  </div>
                  <div class="card-body card-body-hidden" v-else>
                    <button class="btn btn-secondary btn-sm btn-block mb-2" type="button" data-toggle="toast" data-target="#cart-toast">Sold Out</button>
                  
                  </div>


                </div>
                <hr class="d-sm-none">
              </div>
            </div>
          </div>


            <div class="row mt-5" v-else>
              <div class="col-md-12 text-center" >
                <p>
                  Produk belum tersedia saat ini.
                </p>
              </div>
          </div>
          <hr class="my-3">
           <!-- <pagination :data="products" @pagination-change-page="getResult"></pagination> -->
          <!-- Pagination-->
          <!-- <nav class="d-flex justify-content-between pt-2" aria-label="Page navigation">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#"><i class="czi-arrow-left mr-2"></i>Prev</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item d-sm-none"><span class="page-link page-link-static">1 / 5</span></li>
              <li class="page-item active d-none d-sm-block" aria-current="page"><span class="page-link">1<span class="sr-only">(current)</span></span></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">2</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">3</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">4</a></li>
              <li class="page-item d-none d-sm-block"><a class="page-link" href="#">5</a></li>
            </ul>
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" aria-label="Next">Next<i class="czi-arrow-right ml-2"></i></a></li>
            </ul>
          </nav> -->
        </section>


          <!-- <Sidebar /> -->
          <!-- <ProductGrid /> -->
        </div>
      </div>


   </div>
   
</template>

<script>
import axios from "axios";
// import Breadcrumb from '@/components/Breadcrumb.vue'
// import Sidebar from '@/components/Sidebar.vue'
// import ProductGrid from '@/components/ProductGrid.vue'

export default {
  name: 'ShopDiamond',
  components: {
      // Breadcrumb,
      // ProductGrid,
      // Sidebar
  },
   data() {
      return {

        maximum: 0,
        products: [],
        keranjangUser:[]
      }
    }, 
     mounted() {
       
      axios
        .get("http://bmsvuenew-api.test/api/v1/products-diamond")
        .then(res => (this.products = res.data.data.data))
        //  eslint-disable-next-line no-console
        .catch(err => console.log(err));
    },
    methods: {
 
      saveKeranjang(idProduct, nameProduct, priceProduct, photoProduct) {
        var productStored = {
          "id": idProduct,
          "name": nameProduct,
          "price": priceProduct,
          "photo": photoProduct
        }
        this.keranjangUser.push(productStored);
        const parsed = JSON.stringify(this.keranjangUser);
        localStorage.setItem('keranjangUser', parsed);
      },
      getResults(page){
 
        let uri = 'http://bmsvuenew-api.test/api/v1/products?page=' + page;
        this.axios.get(uri).then(response => {
                    return response.data;
                }).then(data => {
                    this.products = data;
                });
      },
    },
   
};

</script>


<style scoped>

</style>

