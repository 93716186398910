<template>
<div id="contact">
 <!-- Page Title (Light)-->
    <div class="bg-secondary py-4">
      <div class="container d-lg-flex justify-content-between py-2 py-lg-3">
        <div class="order-lg-2 mb-3 mb-lg-0 pt-lg-2">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb flex-lg-nowrap justify-content-center justify-content-lg-start">
              <li class="breadcrumb-item"><a class="text-nowrap" href="index.html"><i class="czi-home"></i>Home</a></li>
              <li class="breadcrumb-item text-nowrap active" aria-current="page">Contacts</li>
            </ol>
          </nav>
        </div>
        <div class="order-lg-1 pr-lg-4 text-center text-lg-left">
          <h1 class="h3 mb-0">Contacts</h1>
        </div>
      </div>
    </div>
    <!-- Page Content-->
    <!-- Contact detail cards-->
    <section class="container-fluid pt-grid-gutter">
      <div class="row">
        <div class="col-xl-3 col-md-6 mb-grid-gutter"><a class="card" href="#map" data-scroll>
            <div class="card-body text-center"><i class="czi-location h3 mt-2 mb-4 text-primary"></i>
              <h3 class="h6 mb-2">BATUMULIASHOP.com</h3>
              <p class="font-size-sm text-muted">Suite 16A, Menara Imperium<br />
Metropolitan Kuningan Super Blok, Kaveling No.1
Jl. HR Rasuna Said Jakarta 12980 - Indonesia</p>

            </div></a></div>
        <div class="col-xl-3 col-md-6 mb-grid-gutter">
          <div class="card">
            <div class="card-body text-center"><i class="czi-time h3 mt-2 mb-4 text-primary"></i>
              <h3 class="h6 mb-3">Working hours</h3>
              <ul class="list-unstyled font-size-sm text-muted mb-0">
                <li>Mon - Fri: 10AM - 5PM</li>
                <li class="mb-0">Sat: (by appointment only)</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-grid-gutter">
          <div class="card">
            <div class="card-body text-center"><i class="czi-phone h3 mt-2 mb-4 text-primary"></i>
              <h3 class="h6 mb-3">Phone numbers</h3>
              <ul class="list-unstyled font-size-sm mb-0">
                <li><span class="text-muted mr-1">Telp:</span><a class="nav-link-style" href="tel:+62218354050">021-8354050</a></li>
                <li class="mb-0"><span class="text-muted mr-1">Whatsapp</span><a class="nav-link-style" href="http://wa.me/628176781986">0817 678 1986</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6 mb-grid-gutter">
          <div class="card">
            <div class="card-body text-center"><i class="czi-mail h3 mt-2 mb-4 text-primary"></i>
              <h3 class="h6 mb-3">Email addresses</h3>
              <ul class="list-unstyled font-size-sm mb-0">
                <li><span class="text-muted mr-1"></span><a class="nav-link-style" href="mailto:contact@batumuliashop.com">contact@batumuliashop.com</a></li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
   
    <!-- Split section: Map + Contact form-->
    <div class="container-fluid px-0" id="map">
      <div class="row no-gutters">
        <div class="col-lg-12 iframe-full-height-wrap">
          <iframe class="iframe-full-height" width="100%" height="450" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.4043801812204!2d106.82908465059208!3d-6.210276495482258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f40f14c02735%3A0x553482213f532603!2sBatumuliashop.com!5e0!3m2!1sid!2sid!4v1614952352327!5m2!1sid!2sid"></iframe>

        </div>
        <!-- <div class="col-lg-6 px-4 px-xl-5 py-5 border-top">
          <h2 class="h4 mb-4">Drop us a line</h2>
          <form class="needs-validation mb-3" novalidate>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-name">Your name:&nbsp;<span class="text-danger">*</span></label>
                  <input class="form-control" type="text" id="cf-name" placeholder="John Doe" required>
                  <div class="invalid-feedback">Please fill in you name!</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-email">Email address:&nbsp;<span class="text-danger">*</span></label>
                  <input class="form-control" type="email" id="cf-email" placeholder="johndoe@email.com" required>
                  <div class="invalid-feedback">Please provide valid email address!</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-phone">Your phone:&nbsp;<span class="text-danger">*</span></label>
                  <input class="form-control" type="text" id="cf-phone" placeholder="62 812 000 0000" required>
                  <div class="invalid-feedback">Please provide valid phone number!</div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="cf-subject">Subject:</label>
                  <input class="form-control" type="text" id="cf-subject" placeholder="Provide short title of your request">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="cf-message">Message:&nbsp;<span class="text-danger">*</span></label>
              <textarea class="form-control" id="cf-message" rows="6" placeholder="Please describe in detail your request" required></textarea>
              <div class="invalid-feedback">Please write a message!</div>
            </div>
            <button class="btn btn-primary" type="submit">Send message</button>
          </form>
        </div> -->
      </div>
    </div>
    
    </div>

</template>

<script>


export default {
  name: 'Contacts',
  components: {
  

  }

}
</script>