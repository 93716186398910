<template>
<div class="informasi">
   <!-- Hero section with search-->
    <section class="bg-dark bg-size-cover bg-position-center-x position-relative py-5 mb-5" style="background-image: url(img/pages/help-hero-bg.jpg);"><span class="bg-overlay bg-darker" style="opacity: .65;"></span>
      <div class="bg-overlay-content container py-4 my-3">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <h1 class="text-light text-center">How can we help?</h1>
            <p class="pb-3 text-light text-center">Browse Topics. Find Answers.</p>
          
      
          </div>
        </div>
      </div>
    </section>

    
    <!-- FAQ-->
    <section class="container pt-4 pb-5">
      <h2 class="h3 text-center">F.A.Q.</h2>
      <div class="row pt-4">
        <div class="col-sm-6">
          <ul class="list-unstyled">
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#"><strong>How long will delivery take?</strong></a></li>
            <p class="mb-5"> Delivery will take depending in which state of the country you are in, it can take up to 2 to 7 days nationwide, it all depends on the process of logistics service. As for international shipment it will take about 2 weeks depending on which part of the world you are and which logistic service that you use. </p>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#"><strong>What payment methods do you accept?</strong></a></li>
            <p  class="mb-5">We do accept PayPal, Bank Transfer and Western Union.</p>
            <!-- <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#">Do you ship internationally?</a></li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#">Do I need an account to place an order?</a></li> -->
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#"><strong>How can I track my order?</strong></a></li>
            <p  class="mb-5">You can track your order through our website or through the specific logistic that you have chosen. Shipping delays or lost of package is not our responsibility.</p>
          </ul>
        </div>
        <div class="col-sm-6">
          <ul class="list-unstyled">
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#"><strong>What are the product refund conditions?</strong></a></li>
            <p>All of our products are all 100% guaranteed Natural and all are certified. Refund will occur when gem is not 100% natural. Buying online, you can request for additional pictures and videos and will tell you the condition of the items. Buyer will have to pay shipping charges and item should reach us first and our team will inspect it’s condition, then we will fully refund after deducting shipping charges. If refund is needed, we will give you 7 days inspection nationwide since the day it reaches you and 14 days for international customers.</p>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#"><strong>Do you have discounts for returning customers?</strong></a></li>
            <p>We will have many voucher discounts, and yes we do wholesale for resellers, and there will always be a special price for returning customers, contact us and we will happy to assist you.</p>
            <!-- <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#">How do your referral program work?</a></li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#">Where I can view and download invoices for my orders?</a></li>
            <li class="d-flex align-items-center border-bottom pb-3 mb-3"><i class="czi-book text-muted mr-2"></i><a class="nav-link-style" href="#">Do you provide technical support after the purchase?</a></li> -->
          </ul>
        </div>
      </div>
    </section>
    <!-- Submit request-->
    <section class="container text-center pt-1 pb-5 mb-2">
      <h2 class="h4 pb-3">Haven't found the answer? We can help.</h2><i class="czi-help h3 text-primary d-block mb-4"></i><a class="btn btn-primary" href="http://wa.me/628176781986">Whatsapp Us</a>
      <p class="font-size-sm pt-4">Contact us and we’ll get back to you as soon as possible.</p>
    </section>
</div>
</template>

<script>
export default {
    name: 'Informasi',
  components: {

  }
}
</script>

<style scoped>

</style>